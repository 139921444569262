import React, { useState, useEffect } from 'react';

import { Tab, Nav } from "react-bootstrap";
import FullPageLoader from '../common/FullPageLoader';
import MasterPointConfig from './MasterPointConfig';
import { withTranslation } from 'react-i18next';
import incentive_img from "../../webroot/images/incentive_scheme_img.svg";
import { IncentiveService,PointService } from "../../services";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CommonActions } from "../../store/action";



const PointConfiguration = (props) => {

    const [configList, setConfigList] = useState([]);
    const [activeTab, setState] = useState(1);
    const [preExpandedTabs, setExpandedTab] = useState(-1);
    const [subExpandedTabs, setSubExpandedTabs] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [configData, setConfigData]=useState({});
    const [expiredConfigList, setExpiredConfigList] = useState([]);
    const [formErrors, setformErrors] = useState({});
    const [financierList, setFinancierList] = useState({});
    const [financierDropDown, setFinancierDropDown] = useState({});
    const dispatch = useDispatch(); 
   
    useEffect(() => {
        getIncentiveConfig();
        getPointList();
    }, []);

    useEffect(() => {
        getFinancierList();
        // eslint-disable-next-line
    },[])

    const getFinancierList=()=>{
        setLoading(true)
        dispatch(CommonActions.financierList()).then((res) => {
            setLoading(false);
            if (res && res.status === 200) {
                getFinancierArr(res.data);
            }
        });
    }

    const getFinancierArr=async(fdata)=>{
        const obj = {};
        const obj1 = {};
        fdata.forEach((element, index) => {
            let financierArr = []; 
            let SelectFArr=[] 
            let branch =  element.financier.filter(el => el.type === "Branch Partnership").map(el=>({id:el.value,value:el.label}));

            let hoPartnership = element.financier.filter(el => el.type === "HO Partnership").map(el=>({id:el.value,value:el.label}));

            let others =   element.financier.filter(el => (el.type !== "HO Partnership" && el.type !== "Branch Partnership")).map(el=>({id:el.value,value:el.label}));

           if(branch.length){
            financierArr.push({value : "Branch Partnership",id : "Branch Partnership", childrens : branch});
            }
            if(hoPartnership.length){
                financierArr.push({value:"HO Partnership" , id:"HO Partnership",childrens : hoPartnership});
            }
            if(others.length){
                financierArr.push({value : "Others",id:"Others",childrens:others});
            }
            if(financierArr.length){
                SelectFArr.push({value : "Select All",id:"all",childrens:financierArr})
            }
           
        obj[`${element.application_type_name}`] = element.financier.map(el=>({id:el.value,value:el.label, type:el.type}));
        obj1[`${element.application_type_name}`] = SelectFArr;
        });
        setFinancierList(obj);
        setFinancierDropDown(obj1);
    }
    
    const getIncentiveConfig = () => {
        setLoading(true);
        IncentiveService.getIncentiveConfig().then(function (response){
            setLoading(false);
            if (response.data.status === 200) {
                if (Object.keys(response.data.data).length > 0) {
                    setConfigData(response.data.data)
                }
            } else {
                toast.error(response.data.message);
            }
        }).catch(function (response) {setLoading(false)});
    };

    const getPointList=()=>{
        setLoading(true);
        PointService.getPointAll().then(function (response){
            setLoading(false);
            if (response.data.status === 200) {
                if (Object.keys(response.data.data).length > 0){
                    let activeResponse = response.data.data.active_data;
                    let expiredResponse = response.data.data.expired_data;
                    setConfigList(activeResponse);
                    localStorage.setItem(
                        "main_config_list",
                        JSON.stringify(response.data.data.active_data)
                    );
                    setExpiredConfigList(expiredResponse);
                }
            } else {
                toast.error(response.data.message);
            }
        }).catch(function (response) {setLoading(false) });
    }
    

    const onChange = (event) => {
        let { name, value, id } = event.target;
        const updatedArray = configList.map((item, idx) => {
            if (idx === Number(id)) {
                item[name] = value
                return { ...item};
            }
            // Leave the other items unchanged
            return item;
          });
          setConfigList(updatedArray);
    };

    const confirmationModalResponse = (response) => {
        document.body.classList.remove("overflow-hidden");
        if(response.response){
            let newConfig = configList.filter(ob => (ob.id !== response.type.id || ob.temp_id !== response.type.temp_id));
            setConfigList(newConfig);
        }
    }

    const handleChange = (name, value, type,index) => {
        const updatedArray = configList.map((item, idx) => {
            if (idx === index) {
                if (type === "role" || type === "sales" || type === "month") {
                    item[name] = value;
                }else if (name === "status") {
                    item[name] = (value)?1:0;
                    setformErrors({[index] : {}});
                } 

                if(type === "status" && value){
                    let pointConfigObj = {"application_type": name, "status": (value)?1:0, "parameter_type": null, "finanicer_ids": "", "point": 1,"config_value":null};
                    item.point_config.push(pointConfigObj);
                } else if(type === "status" && !value){
                    item.point_config = item.point_config.filter(config => config.application_type !== name);
                }
                
                if(item.point_config && item.point_config.length > 0 && type !== "status"){
                    item.point_config.forEach(function(el,i) {
                        if(el.application_type === name){
                            item.point_config[parseInt(i)][type] = value;
                        }
                    });
                }
   
                return {...item};
            }
            // Leave the other items unchanged
            return item;
          });
          // Update the state with the modified array
          setConfigList(updatedArray);
    };

    const showExpandedTabUuidTabs = (uuid) => {
        setExpandedTab(uuid);
    }
    const showSubExpandedTabUuid = (uuid) => {
        setSubExpandedTabs(uuid);
    }

    const handleAddPointConfig = () => { 
        let tempConfig = {
        point_config: [],
        tempErrors: {},
        product_type : null,
        user_role: null,
        point_duration : null,
        status:1,
        loading: false,
        activeItem: 1,
        description: "",
        temp_id:(configList && configList.length)? configList.length+1:0
        };
         
        
        const point_config = [];
        point_config.push(tempConfig);
        setformErrors({[configList.length] : {}});
        setConfigList([...configList, ...point_config]);
        setExpandedTab(0);
       
    };


    const validUpdateForm = (data, otherIncentiveList) => { 
        let formIsValid = true;
        let tempErrors = {};

        if(!data.user_role){
            formIsValid = false;
            tempErrors["user_role"] = "User Role is Required"
        }

        if(!data.point_duration){
            formIsValid = false;
            tempErrors["point_duration"] = "Month is Required";
        }

        if(!data.product_type){
            formIsValid = false;
            tempErrors["product_type"] = "Sales Team is Required";
        }

        if(data["user_role"] && data["point_duration"] && data["product_type"] && otherIncentiveList && otherIncentiveList.length > 0){
            let duplicatePointConfig = otherIncentiveList.some((list) => {
                return ((list.user_role === data["user_role"]) && (list.point_duration === data["point_duration"]) && (list.product_type === data["product_type"]));
            });
            if(duplicatePointConfig){
                formIsValid = false;
                tempErrors["user_role"] = props.t('POINT_CONFIG_DETAIL.MESSAGE.ROLE_ALREADY_EXIST');
                tempErrors["point_duration"] = props.t('POINT_CONFIG_DETAIL.MESSAGE.DURATION_ALREADY_EXIST');
                tempErrors["product_type"] = props.t('POINT_CONFIG_DETAIL.MESSAGE.SALES_ALREADY_EXIST');
            }
        }

        if(!data.point_config || data.point_config.length === 0){
            formIsValid = false;
            tempErrors["point_config"] = props.t('POINT_CONFIG_DETAIL.REQUIRED_MSG.POINT_DETAIL');;
        }
        if(data.point_config && data.point_config.length > 0){
            data.point_config.forEach(pointConfig => {
                if(pointConfig.status){
                    if(!pointConfig.parameter_type){
                        formIsValid = false;
                        tempErrors["parameter_type_" + pointConfig.application_type] = props.t('POINT_CONFIG_DETAIL.REQUIRED_MSG.PM');
                    }

                    if(!pointConfig.finanicer_ids){
                        formIsValid = false;
                        tempErrors["finanicer_ids_" + pointConfig.application_type] = props.t('POINT_CONFIG_DETAIL.REQUIRED_MSG.F');
                    }

                    if(!pointConfig.point){
                        formIsValid = false;
                        tempErrors["point_" + pointConfig.application_type] = props.t('POINT_CONFIG_DETAIL.REQUIRED_MSG.P');
                    }
                    if(!pointConfig.config_value){
                        formIsValid = false; 
                        tempErrors["config_value_" + pointConfig.application_type] =  props.t('POINT_CONFIG_DETAIL.REQUIRED_MSG.V'); 
                    }else if(pointConfig.config_value){ 
                        if(pointConfig.config_value.charAt(0) ==="0"){
                            formIsValid = false; 
                            tempErrors["config_value_" + pointConfig.application_type] = props.t('POINT_CONFIG_DETAIL.REQUIRED_MSG.ZERO_ACCEPT');
                        }
                        if(Number(pointConfig.config_value) < 0){
                            formIsValid = false; 
                            tempErrors["config_value_" + pointConfig.application_type] =  props.t('POINT_CONFIG_DETAIL.REQUIRED_MSG.POSITIVE_VALUE_ONLY');
                        }
                    } 
                }

            })
            
        }
       
        return {formIsValid, tempErrors};
    };

    const handleCancel = (element) => {

        setSubExpandedTabs("");; //CLOSE CURRENT TAB

        let { id } = element;
        let tempConfigList = configList;
        let main_config_list = JSON.parse(localStorage.getItem("main_config_list")) ? JSON.parse(localStorage.getItem("main_config_list"))  : {};
        let main_index = 0;
        if (id) {
            main_index = main_config_list.findIndex(
                (p) => p.id === id
            );
        } else {
            main_index = main_config_list.length;
        }
        let main_data = main_config_list[main_index];

        let index = 0;
        if (id) {
            index = tempConfigList.findIndex(
                (p) => p.id === id
            );
        } else {
            index = tempConfigList.length - 1;
        }
        if (main_index === main_config_list.length) {
            tempConfigList = tempConfigList.filter((obj, key)=> key !== index)
            setConfigList(tempConfigList)
        } else {
            tempConfigList[index] = main_data;
            setConfigList(tempConfigList)
        }
    };

 
    const handleUpdate = async (element, indexKey,childRefKey='') => {
        if (childRefKey) {
            setSubExpandedTabs(childRefKey);
        }
        let inputField = element;
        
        let otherconfigList = configList.filter((list, index) => index !== indexKey);
        const {formIsValid, tempErrors} = validUpdateForm(inputField, otherconfigList);
        setformErrors({[indexKey] : tempErrors});
        if(formIsValid){
            saveConfigData(inputField);
        }
    };

   
    const saveConfigData=async(inputField)=>{
        setLoading(true);
        var formData = new FormData();
        formData.append("user_role", inputField.user_role);
        formData.append("product_type", inputField.product_type);
        formData.append("point_duration", inputField.point_duration);
        formData.append("description", inputField.description);
        formData.append("status", (inputField.status) ? 1 : 0);
        formData.append("point_config", JSON.stringify(inputField.point_config)); 
        if(inputField.copy_id){
            formData.append("copy_id" , inputField.copy_id); 
        }
        if(inputField.id){
            formData.append("id", Number(inputField.id));
        } 
        PointService.savePoint(formData).then(function (response){
            setLoading(false);
            if(response.data.status === 200){
                toast.success(response.data.message);
                getPointList();
            }else{
                toast.error(response.data.message); 
            }
        }).catch(function (response) {
            setLoading(false);
        });
    }

    const handleCopyPointConfig = async(data, value) => {   
        let {id, ...dataToCopy} = data; 
        dataToCopy["temp_id"] = (configList && configList.length)? configList.length+1:0
        dataToCopy["copy_id"] = id; 
      
        if (dataToCopy?.point_config?.length) { 
            dataToCopy.point_config = dataToCopy.point_config?.map(({ id, ...rest }) => rest);
        } 
 
        setformErrors({[configList.length] : {}});
        setConfigList([...configList, dataToCopy]);
        setExpandedTab(0);
 
    };

    return (
        <React.Fragment>
            <FullPageLoader show={loading} />
            <div className='container-fluid '>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <div className="tab-line white-bg p-l-r-15 minus-l-r-15 hading-tab-top">
                        <div className="top-heading">
                            <h1>{props.t('COMMON.POINT_CONFIG.HEADING')}</h1>

                            <Nav variant="pills" className="flex-column">
                                <div className="tab-list">
                                    <Nav.Item
                                        onClick={() => {
                                            setState(1);
                                        }}
                                    >
                                        <Nav.Link eventKey="first">{props.t('COMMON.POINT_CONFIG.ACTIVE_CONFIG')}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item
                                        onClick={() => {
                                            setState(2);
                                        }}
                                    >
                                        <Nav.Link eventKey="second">{props.t('COMMON.POINT_CONFIG.EXPIRED_CONFIG')}</Nav.Link>
                                    </Nav.Item>
                                </div>
                            </Nav>

                        </div>

                        {activeTab === 1 && (
                            <button
                                onClick={() => {
                                    handleAddPointConfig();
                                }}
                                className="btn-line btn-add-incentive"
                            >
                                <i className="ic-add"></i>
                                <span>{props.t('COMMON.POINT_CONFIG.ADD_BTN')}</span>
                            </button>
                        )}

                    </div>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                            <div className="add-incentive-accordion">
                                {(configList && configList.length > 0 && Object.keys(configData).length > 0) ?
                                    (<MasterPointConfig
                                        activeTab={activeTab}
                                        configList={configList}
                                        configData={configData}
                                        financierList={financierList}
                                        financierDropDown={financierDropDown}
                                        index={0}
                                        handleChange={(e, i, q, u) => {
                                            handleChange(e, i, q, u);
                                        }}
                                        handleCancel={(e) => {
                                            handleCancel(e);
                                        }}
                                        onChange={(e, i, q, u) => onChange(e, i, q, u)}
                                        confirmationModalResponse={confirmationModalResponse}
                                        preExpandedTabs={preExpandedTabs}
                                        showExpandedTabUuid={showExpandedTabUuidTabs}
                                        subExpandedTabs={subExpandedTabs}
                                        getPointList={getPointList}
                                        showSubExpandedTabUuid={showSubExpandedTabUuid}
                                        handleAddPointConfig={handleAddPointConfig}
                                        loading={loading}
                                        handleUpdate={handleUpdate}
                                        tempErrors={formErrors}
                                        handleCopyPointConfig = {(e, q) => {
                                            handleCopyPointConfig(e, q);
                                        }}
                                    />
                                    ) : (
                                        <div className="active-scheme-outer">
                                            <div className="white-bg">
                                                <img src={incentive_img} className="" alt="" />
                                                <span>{props.t('POINT_CONFIG_DETAIL.MESSAGE.NO_CONFIG_FOUND')}</span>
                                                <button
                                                    className="btn-primary"
                                                    onClick={handleAddPointConfig}
                                                >
                                                    {props.t('COMMON.POINT_CONFIG.ADD_BTN')} </button>
                                            </div>
                                        </div>
                                    )

                                }
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                            <div className="add-incentive-accordion">
                            {(expiredConfigList && expiredConfigList.length > 0 && Object.keys(configData).length > 0)  ? 
                            (<MasterPointConfig
                                activeTab={activeTab}
                                configList={expiredConfigList}
                                configData={configData}
                                financierList={financierList}
                                financierDropDown={financierDropDown}
                                index={0}
                                handleChange={(e, i, q, u) => {
                                    handleChange(e, i, q, u);
                                }}

                                onChange={(e, i, q, u) => onChange(e, i, q, u)}
                                confirmationModalResponse={confirmationModalResponse}
                                preExpandedTabs={preExpandedTabs}
                                showExpandedTabUuid={showExpandedTabUuidTabs}
                                subExpandedTabs={subExpandedTabs}
                                showSubExpandedTabUuid={showSubExpandedTabUuid}
                                handleAddPointConfig={handleAddPointConfig}
                                getPointList={getPointList}
                                loading={loading}
                                handleUpdate={(e, i, q) => {
                                    handleUpdate(e, i, q);
                                }}
                                tempErrors={formErrors}
                            />
                            ) : (
                                <div className="active-scheme-outer">
                                    <div className="white-bg">
                                        <img src={incentive_img} className="" alt="" />
                                        <span>{props.t('POINT_CONFIG_DETAIL.MESSAGE.NO_EXPIRED_CONFIG_FOUND')}</span>
                                    </div>
                                </div>
                            )}
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>

            </div>

        </React.Fragment>
    );
   
}


export default withTranslation('common')(PointConfiguration); 