import React, { useState} from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import ToggleSwitch from "../common/ToggleSwitch";
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import BasicDetails from './BasicDetails';
import PointDetails from './PointDetails';
import ConfirmationModal from "../common/ConfirmationModal";
import { PointService } from "../../services";
import { toast } from "react-toastify";

const MasterPointConfig = (props) => {
  let {configData,configList,financierList,financierDropDown} = props; 

    const [state, setState] = useState({
      areaExpandedParent: false,
      areaExpandedChild: false,
      DeleteIncentivepopup: false,
      ModifyStatusPopup: false,
      CancelIncentivepopup : false,
      element_selected: {},
      index:''

    });
    

    const showModalpopup = (value,element,index, showExpiredToggle = false) => {
      if (!element.is_expired || showExpiredToggle) {
        let duplicatePointConfig = false;
          if(showExpiredToggle && element["user_role"] && element["point_duration"] && element["product_type"] && props.activeConfigList && props.activeConfigList.length > 0){
            duplicatePointConfig = props.activeConfigList.some((list) => {
                return ((list.user_role === element["user_role"]) && (list.point_duration === element["point_duration"]) && (list.product_type === element["product_type"]));
            });
          }
          if(duplicatePointConfig){
            toast.error("You can not activate this Configuration as it already exists")
          } else {
            setState({ element_selected:element,index:index,[value]: true });
            document.body.classList.add("overflow-hidden");
          }
      }
    };
     
    /*
    * Delete point Config
    */
    const confirmationModalResponse = (response) => { 
      setState({ DeleteIncentivepopup: false });
      if (response.response) { 
          if(response.type.id) {
            PointService.updatePoint({
              id: response.type.id,
              is_deleted: "1",
            }).then(function (response) {
              if (response.data.status === 200) {
                toast.success(response.data.message);
              }
          });
          
        }
        props.confirmationModalResponse(response);
      }
    }
    
    /*
    * Cancel Point Config
    */
    const cancelModalResponse = (response) => {
      if (response.response) {
        props.handleCancel(response.type);
      } 
      document.body.classList.remove("overflow-hidden");
      setState({ CancelIncentivepopup: false, areaExpandedChild: false });
    };
    
    /*
    * Deactivate or Activate point Config
    */
    const activeModalResponse = (response) => {
      setState({ ModifyStatusPopup: false });
      if(response.response){
          if (response.type.id) {
              PointService.updatePoint({
                id: response.type.id,
                is_active: response.type.status ? 0 : 1,
              }).then(function (response) {
                  if(response.data.status === 200) {
                    toast.success(response.data.message);
                    props.getPointList();
                  }
              });
          }
        props.handleChange("status",!response.type.status,'',state.index);
        setState({index:''});
      }
      document.body.classList.remove("overflow-hidden");
    }

    const handleParentClicks = (e) => {
      e.stopPropagation();
      if (e.target.className.indexOf("accordion__button") !== -1) {
        if (e.target["ariaExpanded"] === "true") {
          props.showExpandedTabUuid(-1);
          setState({ areaExpandedParent: false });
        } else {
          props.showExpandedTabUuid(props.index);
          setState({ areaExpandedParent: true });
        }
      }
  
      //ON HEADING CLICK OPEN/CLOSE HANDLER
      if (
        e.target.attributes &&
        e.target.attributes.accordionstatus &&
        e.target.attributes.accordionstatus.nodeValue
      ) {
        if (e.target.attributes.accordionstatus.nodeValue === "open") {
          props.showExpandedTabUuid(-1);
          setState({ areaExpandedParent: false });
        } else {
          props.showExpandedTabUuid(props.index);
          setState({ areaExpandedParent: true });
        }
      }
    };
    
    const handleSubParentClicks = (e, childRefUUid) => {
      e.stopPropagation(); 
      //ON TAB CLICK OPEN/CLOSE HANDLER
      if (typeof e.target.className =='string' && e.target.className.indexOf("accordion__button") !== -1) {
        if (e.target["ariaExpanded"] === "true") {
          props.showSubExpandedTabUuid(-1);
            setState({ areaExpandedChild: false });
        } else {
          props.showSubExpandedTabUuid(childRefUUid);
        }
      }
  
      //ON HEADING CLICK OPEN/CLOSE HANDLER
      if (
        e.target.attributes &&
        e.target.attributes.accordionstatus &&
        e.target.attributes.accordionstatus.nodeValue
      ) {
        if(e.target.attributes.accordionstatus.nodeValue === "open") {
          props.showSubExpandedTabUuid(-1);
          setState({ areaExpandedChild: false });
        } else {
          props.showSubExpandedTabUuid(childRefUUid);
        }
      }
    };

   const handleNestedClicks = (e) => {
      e.stopPropagation();
    };

   const handleAddPointConfig = (element) => {
    props.showExpandedTabUuid(props.index);
    props.handleAddPointConfig();
  };

  const handleCopyPointConfig = (data, id) => { 
    props.showExpandedTabUuid(props.index);
    props.handleCopyPointConfig(data, id);
  };

  
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Accordion allowZeroExpanded="true">
            <AccordionItem
                key={props.index}
                uuid={props.index}
                onClick={handleParentClicks}
                dangerouslySetExpanded={
                  props.preExpandedTabs === props.index || state.areaExpandedParent
                }
              >
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h2
                    accordionstatus={
                      props.preExpandedTabs === props.index || state.areaExpandedParent
                      ? "open"
                      : "close"
                    }
                  >
                    {props.t("POINT_CONFIG_DETAIL.TITLE")} ({configList.length})
                  </h2>
                  {props.activeTab === 1 && (
                    <button
                      onClick={() => {
                        handleAddPointConfig();
                      }}
                      className="btn-line"
                    >
                      {" "}
                      {props.t("FORM.ADD_NEW")}
                    </button>
                  )}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="sub-accordion">

                  <Accordion allowZeroExpanded="true">
                  {configData && configList.length && configList.map((data, index) =>{ 
                    let role=configData.user_point_role.find((el)=>el.value===+data.user_role);
                    let point_duration=configData.scheme_duration.find((el)=>el.value===+data.point_duration);
                    let duration_value=(data && data.is_expired)?data.point_duration_value.split(','):'';
                  
                    let current_year = new Date().getFullYear();
                    let current_month =  new Date().getMonth() + 1;
                    let showExpiredToggle = false;
                    if(duration_value && ((current_year === Number(duration_value[1]) && current_month <=  Number(data.point_duration)) || (current_year < Number(duration_value[1])))){
                      showExpiredToggle = true;
                    } 
                    
                    return(
                    <AccordionItem 
                      key={"parent" + index} 
                      uuid={data.id} 
                      onClick={(e) => {
                        handleSubParentClicks(e,index + "__" + data.id)
                      }}
                      dangerouslySetExpanded={
                        props.subExpandedTabs === index + "__" + data.id || state.areaExpandedChild
                      }
                      >
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <h2 accordionstatus={props.subExpandedTabs === index + "__" + data.id ? "open": "close" }>

                            {(data && data.user_role && role ? (role.label) : 'Role') + ' - ' +(data.product_type ? (data.product_type) : 'Sales Team') + ' - ' + (data.point_duration && point_duration?(point_duration.label) : (data && data.is_expired)?duration_value[0]:'Month')}

                          </h2>
                          <ul className="right-action-item" onClick={handleNestedClicks}>
                              {
                                !data.is_expired && data.id &&
                                <li>
                                  <i className="ic-content_copy"
                                    onClick={() => {
                                      handleCopyPointConfig(data, data.id);
                                    }}></i>
                                </li>

                              }
                            <div className="on-off-switch">
                              {
                                (!data.is_expired)?
                                <ToggleSwitch
                                  status={data.status}
                                  is_expired={data.is_expired && !showExpiredToggle}
                                   onChange={() => {showModalpopup("ModifyStatusPopup",data,index, showExpiredToggle);}}
                                />:
                                <ToggleSwitch
                                  status={data.status}
                                  is_expired={data.is_expired && !showExpiredToggle}
                                  onChange={''}
                                />
                              }
                            </div>
                            { 
                             (!data.is_expired)?
                              <li onClick={() => {showModalpopup("DeleteIncentivepopup",data,index);
                              }}><i className="ic-delete-icn"></i>
                              </li>:
                              <li><i className="ic-delete-icn"></i></li>
                            }

                          </ul>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <h3 className="h3">{props.t('COMMON.BASIC_DETAIL')} </h3>
                        <div className={'basic-top-filter-outer'}>
                          <BasicDetails
                            configData={configData}
                            element={data}
                            onChange={(e, i, q, u) => {
                              props.handleChange(e, i, q, u);
                            }}
                            index={index}
                            tempErrors={
                              props.tempErrors && props.tempErrors[index]
                                ? props.tempErrors[index]
                                : {}
                            }
                            />
                        </div>
                       
                        <div>
                          <PointDetails
                            element={data.point_config}
                            is_expired={data.is_expired}
                            configData={configData}
                            financierList={financierList}
                            financierDropDown={financierDropDown}
                            index={index}
                            onChange={(e, i, q, u) => {
                              props.handleChange(e, i, q, u);
                            }}
                            tempErrors={
                              props.tempErrors && props.tempErrors[index]
                                ? props.tempErrors[index] : {}
                            }
                            />
                        </div>
                        <div className="description-card-outer">
                          <h3>{props.t("FORM.DESCRIPTION")}</h3>
                          <textarea
                            value={data.description ? data.description : ""}
                            name="description"
                            id={index}
                            onChange={(e, i, q, u) => props.onChange(e, i, q, u)}
                            rows={5}
                            placeholder={props.t("FORM.DESCRIPTION_ENTER")}
                            maxLength="250"
                          ></textarea>
                          <div className="btn-save-remarks">
                            {
                              (!data.is_expired)?
                              <button
                                disabled={props.loading || data.is_expired}
                                onClick={(e) => {props.handleUpdate(data,index,index + "__" + data.id);}}
                                type="button"
                                className="btn-primary" >
                                {" "}{(data && data.id)?props.t("FORM.UPDATE"):props.t("FORM.ADD_BTN")}
                              </button>:
                               <button
                               disabled={props.loading || data.is_expired}
                               type="button"
                               className="btn-primary" >
                               {" "}{(data && data.id)?props.t("FORM.UPDATE"):props.t("FORM.ADD_BTN")}
                               </button>
                              }

                              {
                                (!data.is_expired)? 
                                  <button
                                    disabled={data.is_expired}
                                    onClick={() => {showModalpopup("CancelIncentivepopup",data,index);}}
                                    type="button"
                                    className="btn-line m-sm-l"
                                  >{" "}{props.t("FORM.CANCEL")}
                                  </button>: 
                                  <button
                                    disabled={data.is_expired}
                                    type="button"
                                    className="btn-line m-sm-l"
                                  >{" "}{props.t("FORM.CANCEL")}
                                  </button>
                              }
                            
                            
                          </div>
                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>)
                  })}
                  </Accordion>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>

        {state.DeleteIncentivepopup ? (
          <ConfirmationModal
            confirmationData={{
              show: state.DeleteIncentivepopup,
              confirmationText: props.t("POINT_CONFIG_DETAIL.MESSAGE.DELETE_CONFIG"),
              type:state.element_selected,
            }}
            confirmationModalResponse={(data) =>
              confirmationModalResponse(data)
            }
          />
        ) : null}

        {state.CancelIncentivepopup ? (
          <ConfirmationModal
            confirmationData={{
              show: state.CancelIncentivepopup,
              confirmationText: props.t("POINT_CONFIG_DETAIL.MESSAGE.CANCEL_CONFIG"),
              type: state.element_selected,
            }}
            confirmationModalResponse={(data) =>
              cancelModalResponse(data)
            }
          />
        ) : null}

        {state.ModifyStatusPopup ? (
          <ConfirmationModal
            confirmationData={{
              show: state.ModifyStatusPopup,
              confirmationText:state.element_selected.status
                ? props.t('POINT_CONFIG_DETAIL.MESSAGE.INACTIVE_CONFIG')
                : props.t("POINT_CONFIG_DETAIL.MESSAGE.ACTIVE_CONFIG"),
              type:state.element_selected,
            }}
            confirmationModalResponse={(data) =>
              activeModalResponse(data)
            }
          />
        ) : null}
      </React.Fragment>
    );

}
const mapStateToProps = (state, ownProps) => {
  return {
    userInfo: (state.auth.authUser) ? state.auth.authUser.user_data : {}
  };
}
export default withTranslation('common')(connect(mapStateToProps)(MasterPointConfig)); 