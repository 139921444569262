import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Dropdown } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CustomValueContainer from '../../common/CustomValueContainer' 
import { ARO_ROLE_ID,BRO_ROLE_ID, AGENT_ROLE_ID } from "../../../config/constant"
import { CommonActions } from "../../../store/action";
import { useDispatch } from "react-redux";

const MoreFilter = (props) => {
   const [EditedStatus, setEditedStatus] = useState([]);
   const [ApprovalStatusOption,setApprovalStatusOption] = useState([]);
   const [LevelOption, setLevelOption] = useState([]);  
   const [taggingOption, setTaggingOption] = useState([]);
    
   const dispatch = useDispatch();

   let { selectedFilters} = props;
   
  useEffect(()=>{
    setEditedStatus(props.incentiveConfigData.edited_status)
    setApprovalStatusOption(props.incentiveConfigData.sfa_user_status)
    setLevelOption(props.incentiveConfigData.agent_level)

    if(props.incentiveConfigData && props.incentiveConfigData.user_level){
        let user_level = props.incentiveConfigData.user_level.map((ob) => {
            return {
                ...ob,
                value: ob.id,
                label: ob.user_name,
                name: ob.user_name
            };
        });
        setTaggingOption(user_level)
    }
    
 // eslint-disable-next-line react-hooks/exhaustive-deps
   },[props.incentiveConfigData.edited_status]);
  
    const changeHandler = (value, name) => {
       if(value){
        props.updateFilters({ ...selectedFilters, [name]: value.value })
       }else{
        
          value = undefined;
          // For delete more filter into payload on Cross 
        
          if(name === 'tagging'){
             delete selectedFilters.tagging
          } 
          if(name === 'editedStatus'){
            delete selectedFilters.editedStatus
         } 
         if(name === 'userStatus'){
            delete selectedFilters.userStatus
         } 
         if(name === 'level'){
            delete selectedFilters.level
         } 

          props.updateFilters({ ...selectedFilters, [name]: value })
          
          }     
        }

    const searchFilter = () => {
        dispatch(CommonActions.checkedAllStatus(false));
        dispatch(CommonActions.updateApproveButtonStatus(false));
        dispatch(CommonActions.getMultiSelectedLoans([]));
        dispatch(CommonActions.setExcludeMultiSelectedSfa([]));
        dispatch(CommonActions.pageStatusType(''));
        if(Object.keys(selectedFilters).length){
            // let myob = {...selectedFilters, page_no: 1, pageName: props.action}  -- never used
         
            props.getDashboardList({...selectedFilters, page_no: 1, pageName: props.action}) 
            
        }else{
            toast.error("Please select any filter")
        }
    } 
   
    const resetFilter = async () => {
        dispatch(CommonActions.checkedAllStatus(false));
        dispatch(CommonActions.updateApproveButtonStatus(false));
        dispatch(CommonActions.getMultiSelectedLoans([]));
        dispatch(CommonActions.setExcludeMultiSelectedSfa([]));
        dispatch(CommonActions.pageStatusType(''));
        if(props.resetSearchFilter){
            props.resetSearchFilter();
        } 
        props.resetFilters()

    }

    const incentiveDataSearch = () => { 
        searchFilter()
    } 
    return (
        <React.Fragment>
            <div className="filter-option">
                <Dropdown>
                    <div className="text-btn d-flex">
                        {props.pageAction
                        ?
                        <Dropdown.Toggle variant="success" id="dropdown-basic">

                            <span className="more-filter-txt" style= {{cursor:'pointer'}}>More</span>
                            <span className="leass-filter-txt" style= {{cursor:'pointer'}}>Less</span>
                        </Dropdown.Toggle>
                        :
                        ''
                        }

                        <div className="btn-submit-reset top-btn-none">
                            <button className="btn-primary" onClick={incentiveDataSearch}>Search</button>
                            <button className="btn-reset" style= {{cursor:'pointer'}} onClick = {resetFilter}>Reset</button>
                        </div>
                    </div>
                    <Dropdown.Menu>
                        <div className="more-filter-option">
                            <h3>More Filters</h3>
                            <div className="filter-form">
                                {
                                    [ARO_ROLE_ID, BRO_ROLE_ID].includes(+selectedFilters.role_id) && <fieldset className="single-select">
                                    <div className="material">
                                        <Select
                                            components={{ ValueContainer: CustomValueContainer }}
                                            value={
                                                selectedFilters.tagging
                                                    ? taggingOption.filter((tagging) => tagging.value === selectedFilters.tagging)
                                                    : []
                                            }
                                            onChange={(val) => changeHandler(val, "tagging")}
                                            options={taggingOption}
                                            placeholder="Tagging"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            isSearchable={false}
                                            isClearable={true}
                                        />
                                    </div>
                                </fieldset>
                                }
                                
                                {
                                    selectedFilters.role_id === AGENT_ROLE_ID && <fieldset className="single-select">
                                    <div className="material">
                                        <Select components={{ ValueContainer: CustomValueContainer }}
                                            value={selectedFilters.level ? LevelOption.filter(level=> level.value === selectedFilters.level) : []}
                                            onChange={(val)=>changeHandler(val, 'level')}
                                            options={LevelOption}
                                            placeholder="Level"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            isSearchable={false}
                                            isClearable={true}
                                        />
                                    </div>
                                </fieldset>
                                }
                                

                                <fieldset className="single-select">
                                    <div className="material">
                                    <Select components={{ ValueContainer: CustomValueContainer }}
                                            value={ApprovalStatusOption ? ApprovalStatusOption.filter(ob=> (ob.value === selectedFilters.userStatus)) : []}
                                            onChange={(val)=>changeHandler(val, 'userStatus')}
                                            options={ApprovalStatusOption}
                                            placeholder="User Status"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            isSearchable={false}
                                            isClearable={true}
                                        />
                                    </div>
                                </fieldset>
                                <fieldset className="single-select">
                                    <div className="material">     
                                    <Select components={{ ValueContainer: CustomValueContainer }}
                                            value={selectedFilters.editedStatus ? EditedStatus.filter(status=> status.value === selectedFilters.editedStatus) : []}
                                            onChange={(val)=>changeHandler(val, 'editedStatus')}
                                            options={EditedStatus}
                                            placeholder="Edited Status"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            isSearchable={false}
                                            isClearable={true}
                                        />
                                    </div>
                                </fieldset>
                                {/* <fieldset className="single-select">
                                    <div className="material">
                                    <Select components={{ ValueContainer: CustomValueContainer }}
                                            value={selectedFilters.uploadStatus ? UploadStatus.filter(status=> status.value === selectedFilters.uploadStatus) : []}
                                            onChange={(val)=>changeHandler(val, 'uploadStatus')}
                                            options={UploadStatus}
                                            placeholder="Upload Status"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                        />
                                    </div>
                                </fieldset> */}

                            </div>

                            <div className="btn-submit-reset more-filter-btn p-sm-t">
                                <button className="btn-primary" onClick={searchFilter}>Search</button>
                                <button className="btn-reset" onClick={resetFilter} style ={{cursor:'pointer'}}>Reset</button>
                            </div>

                        </div>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

        </React.Fragment>
    )
}
export default MoreFilter;